<template>
  <div id='page' class='fill-height pt-1'>
    <v-container class='ml-0' style='max-width: 1200px;'>
      <v-card class='rounded-lg' :loading='loading' :disabled='loading'>
        <v-card-text>
          <v-data-table
            disable-pagination hide-default-footer
            :items='users'
            :headers='headers'
          >
          <template v-slot:header.actions>
            <v-btn class='primary mb-4' @click='clickCreateUser'><v-icon>mdi-plus</v-icon> User</v-btn>
          </template>
          <template v-slot:item.actions="{item}">
            <v-btn fab small text class='primary--text' @click='clickEditUser(item)'><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn fab small text class='primary--text' @click='passwordReset(item)'><v-icon>mdi-lock-reset</v-icon></v-btn>
            <v-btn fab small text class='primary--text' @click='clickPermissions(item)'><v-icon>mdi-shield-star</v-icon></v-btn>
            <v-btn fab small text class='primary--text' @click='clickSchedules(item)'><v-icon>mdi-chart-gantt</v-icon></v-btn>
            <v-btn fab small text class='primary--text' @click='clickDeleteUser(item)'><v-icon>mdi-trash-can</v-icon></v-btn>
          </template>
          </v-data-table>
        </v-card-text>
      </v-card>

    </v-container>

    <v-dialog scrollable persistent
      :value='userDetails.mode'
      max-width='600'
    >
      <v-card :loading='userDetails.loading' :disabled='userDetails.loading'>
        <v-form @submit.prevent='submitUserDetails'>
          <v-card-title class='primary white--text'>{{userDetails.mode}} User</v-card-title>
          <v-card-text class='pt-5'>
            <v-row>
              <v-col cols=12><v-text-field label='First Name' v-model='userDetails.name_first' outlined hide-details /></v-col>
              <v-col cols=12><v-text-field label='Last Name' v-model='userDetails.name_last' outlined hide-details /></v-col>
              <v-col cols=12><v-text-field label='Email Address' v-model='userDetails.email' outlined hide-details /></v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='userDetails.mode=null'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150'  type='submit'>{{userDetails.mode}}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog scrollable persistent
      :value='userPermissions.show'
      max-width='500'
    >
      <v-card :loading='userPermissions.loading' :disabled='userPermissions.loading'>
        <v-form @submit.prevent='submitUserPermissions'>
          <v-card-title class='primary white--text'>User Permissions</v-card-title>
          <v-card-text class='pt-5' style='height:50vh;max-height:450px; overflow-y: auto;'>
            <v-row>
              <v-col cols=12>
                <v-treeview
                  v-model='userPermissions.permissions'
                  selectable open-all open-on-click
                  :items='permissionsAvailable' item-key='code' item-text='label'
                  selected-color="primary" dense
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions class=''>
            <v-spacer />
            <v-btn large color='secondary float-right' width='150' @click='userPermissions.show=false'>Cancel</v-btn>
            <v-btn large color='primary float-right' width='150' type='submit'>Update</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <UserSchedules ref="UserSchedules" />

  </div>
</template>

<style lang="scss" scoped>
#page{
  overflow-y: auto;
}
</style>


<script>
import { mapActions } from 'vuex';
import UserSchedules from '../../components/Dialogs/UserSchedules.vue';

export default {
  name: 'UserSettings',
  components: {
    UserSchedules
  },
  data(){ return {
    users: [],
    headers: [
      { text: "First Name", value: 'name_first' },
      { text: "Last Name", value: 'name_last' },
      { text: "Email Address", value: 'email' },
      { text: "", value: 'actions', align: 'end', sortable: false, },
    ],
    loading: true,
    userDetails: {
      mode: null, //null (hide), create, edit
      loading: false,
      xid: null,
      name_first: null,
      name_last: null,
      email: null,
    },
    permissionsAvailable: [
      { label: "Full Access", code: "*full", children: [
        { label: "My Work", code: "MY_WORK" },
        { label: "Customers", code: "CUSTOMERS" },
        { label: "Jobs", code: "JOBS" },
        { label: "File Inbox", code: "FILEBOX" },
        { label: "Sensitive Files", code: "SENSITIVE" },
        { label: "Taskflows", code: "*taskflows", children: [
          { label: "View Taskflows", code: "TASKFLOW_VIEW" },
          { label: "Modify Taskflows", code: "TASKFLOW_EDIT" },
        ] },
        { label: "Scheduling", code: "MANAGE_SCHEDULE" },
        { label: "Settings", code: "*settings", children: [
          { label: "Users", code: "USERS" },
          { label: "Stages", code: "STAGES" },
          { label: "Billing States", code: "BILLING_STAGES" },
          { label: "Measure", code: "SETTINGS_MEASURE" },
          { label: "Customers", code: "SETTINGS_CUSTOMER" },
          { label: "Scheduling", code: "SETTINGS_SCHEDULE" },
        ] },
      ]},
    ],
    userPermissions: {
      show: false,
      loading: false,
      xid: null,
      permissions: [],
    },
  }},
  computed: {
  },
  watch:{
    $route: { handler(){ let t = `Users`; this.SET_TITLE(t); }, immediate: true },
  },
  methods: {
    ...mapActions('UI', ['SET_TITLE', 'ALERT', 'CONFIRM']),
    ...mapActions('API', ['API']),
    doneLoading(doneLoading = true){ this.loading = !doneLoading; },

    refreshUsers(){
      this.doneLoading(false);
      this.API({ method: 'GET', endpoint: 'users' })
      .then(users => { this.users = users; })
      .finally(this.doneLoading);
    },

    clickEditUser(user){
      let { xid, name_first, name_last, email } = user;
      this.userDetails = {
        ...this.userDetails,
        xid, name_first, name_last, email,
        loading: false, mode: 'Update',
      };
    },

    clickCreateUser(){
      let xid, name_first, name_last, email = null;
      this.userDetails = {
        ...this.userDetails,
        xid, name_first, name_last, email,
        loading: false, mode: 'Create',
      };
    },

    submitUserDetails(){
      if(this.userDetails.mode == 'Update') this.updateUser();
      if(this.userDetails.mode == 'Create') this.createUser();
    },

    updateUser(){
      this.userDetails.loading = true;
      let { xid, name_first, name_last, email } = this.userDetails;
      let data = { name_first, name_last, email };
      this.API({ method: 'PATCH', endpoint: `users/${xid}`, data })
      .then(()=>{
        this.userDetails.mode = null;
        this.refreshUsers();
      })
      .finally(()=> { this.userDetails.loading = false; });
    },

    createUser(){
      this.userDetails.loading = true;
      let { name_first, name_last, email } = this.userDetails;
      let data = { name_first, name_last, email } ;
      this.API({ method: 'POST', endpoint: 'users/create', data })
      .then(()=>{
        this.userDetails.mode = null;
        this.refreshUsers();
      })
      .finally(()=> { this.userDetails.loading = false; });
    },

    clickDeleteUser(user){
      this.CONFIRM({ title: 'Delete User', message: `Deleting ${user.name_first}'s accound cannot be undone.\n\nDo you wish to continue?'` })
      .then(() => {
        this.doneLoading(false);
        let data = { email: user.email };
        this.API({ method: 'DELETE', endpoint: `users/${user.xid}`, data })
        .then(()=>{
          this.refreshUsers();
          this.ALERT({ title: "Delete User", message: `The user account has been deleted.` });
        })
        .finally(this.doneLoading);
      });
    },

    passwordReset(user){
      this.CONFIRM({ title: 'Password Reset', message: `Send ${user.name_first} a password reset link via email?` })
      .then(() => {
        this.doneLoading(false);
        let data = { email: user.email };
        this.API({ method: 'POST', endpoint: 'auth/reset_request', data })
        .then(()=>{
          this.ALERT({ title: "Password Reset", message: `We have sent ${user.name_first} a password reset link via email.\nThe link will expire in 30 minutes.\n\nUsers may also request a new password from the sign-in screen.` });
        })
        .finally(this.doneLoading);
      });
    },

    clickPermissions(user){
      this.userPermissions = {
        ...this.userPermissions,
        show: true, loading: true, xid: user.xid, permissions: [],
      };
      this.API({ method: 'GET', endpoint: `users/${user.xid}/permissions` })
      .then(perms => { this.userPermissions.permissions = perms; })
      .finally(() => { this.userPermissions.loading = false; });
    },

    submitUserPermissions(){
      let data = { permissions: this.userPermissions.permissions };
      this.userPermissions.loading = true;
      this.API({ method: 'PATCH', endpoint: `users/${this.userPermissions.xid}/permissions`, data })
      .then(() => { this.userPermissions.show = false; })
      .finally(() => { this.userPermissions.loading = false; });
    },

    clickSchedules(user){
      this.$refs.UserSchedules.open(user.xid);
    },

  },
  mounted(){
    this.refreshUsers();
  }
}
</script>
