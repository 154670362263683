var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ListModal', {
    ref: "Modal",
    attrs: {
      "title": "Schedules",
      "load-items": _vm.loadItems,
      "item-key": "xid",
      "item-text": "title"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.toggle(item);
            }
          }
        }, [_c('v-icon', [_vm._v("$checkbox" + _vm._s(_vm.scheduleIds.includes(item.xid) ? 'On' : 'Off'))])], 1)];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }