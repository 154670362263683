var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fill-height pt-1",
    attrs: {
      "id": "page"
    }
  }, [_c('v-container', {
    staticClass: "ml-0",
    staticStyle: {
      "max-width": "1200px"
    }
  }, [_c('v-card', {
    staticClass: "rounded-lg",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_c('v-card-text', [_c('v-data-table', {
    attrs: {
      "disable-pagination": "",
      "hide-default-footer": "",
      "items": _vm.users,
      "headers": _vm.headers
    },
    scopedSlots: _vm._u([{
      key: "header.actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "primary mb-4",
          on: {
            "click": _vm.clickCreateUser
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v(" User")], 1)];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clickEditUser(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.passwordReset(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-lock-reset")])], 1), _c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clickPermissions(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-shield-star")])], 1), _c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clickSchedules(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-chart-gantt")])], 1), _c('v-btn', {
          staticClass: "primary--text",
          attrs: {
            "fab": "",
            "small": "",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clickDeleteUser(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-trash-can")])], 1)];
      }
    }])
  })], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "value": _vm.userDetails.mode,
      "max-width": "600"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.userDetails.loading,
      "disabled": _vm.userDetails.loading
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitUserDetails.apply(null, arguments);
      }
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(_vm._s(_vm.userDetails.mode) + " User")]), _c('v-card-text', {
    staticClass: "pt-5"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "First Name",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userDetails.name_first,
      callback: function callback($$v) {
        _vm.$set(_vm.userDetails, "name_first", $$v);
      },
      expression: "userDetails.name_first"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Last Name",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userDetails.name_last,
      callback: function callback($$v) {
        _vm.$set(_vm.userDetails, "name_last", $$v);
      },
      expression: "userDetails.name_last"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Email Address",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userDetails.email,
      callback: function callback($$v) {
        _vm.$set(_vm.userDetails, "email", $$v);
      },
      expression: "userDetails.email"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {}, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "secondary float-right",
      "width": "150"
    },
    on: {
      "click": function click($event) {
        _vm.userDetails.mode = null;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary float-right",
      "width": "150",
      "type": "submit"
    }
  }, [_vm._v(_vm._s(_vm.userDetails.mode))])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "value": _vm.userPermissions.show,
      "max-width": "500"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.userPermissions.loading,
      "disabled": _vm.userPermissions.loading
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitUserPermissions.apply(null, arguments);
      }
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("User Permissions")]), _c('v-card-text', {
    staticClass: "pt-5",
    staticStyle: {
      "height": "50vh",
      "max-height": "450px",
      "overflow-y": "auto"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-treeview', {
    attrs: {
      "selectable": "",
      "open-all": "",
      "open-on-click": "",
      "items": _vm.permissionsAvailable,
      "item-key": "code",
      "item-text": "label",
      "selected-color": "primary",
      "dense": ""
    },
    model: {
      value: _vm.userPermissions.permissions,
      callback: function callback($$v) {
        _vm.$set(_vm.userPermissions, "permissions", $$v);
      },
      expression: "userPermissions.permissions"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {}, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "secondary float-right",
      "width": "150"
    },
    on: {
      "click": function click($event) {
        _vm.userPermissions.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "large": "",
      "color": "primary float-right",
      "width": "150",
      "type": "submit"
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1), _c('UserSchedules', {
    ref: "UserSchedules"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }