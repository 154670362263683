<template>
  <div>

    <ListModal
      ref='Modal' title='Schedules'
      :load-items='loadItems'
      item-key='xid' item-text='title'
    >
      <template #actions="{item}">
          <v-btn icon color="primary" @click="toggle(item)"><v-icon>$checkbox{{ scheduleIds.includes(item.xid) ? 'On' : 'Off' }}</v-icon></v-btn>
      </template>
    </ListModal>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Deferred from '@/helpers/Deferred'
import ListModal from '@/components/Common/ListModal'

export default {
  name: 'UserSchedules',

  components: {
    ListModal,
  },

  props: {},

  data(){
    return {
      userId: null,
      schedules: [],
    }
  },

  computed: {
    scheduleIds(){ return this.schedules.map(s => s.xid); }
  },

  methods: {
    ...mapActions("API", ['API']),

    open(userId){
      this.userId = userId;
      this.$refs.Modal.open();
    },

    loadItems(){
      let { resolve, reject, promise } = new Deferred();
      this.getUserSchedules()
      .then(this.getAllSchedules)
      .then(resolve)
      .catch(reject);
      return promise;
    },

    getAllSchedules(){
      return this.API({ method: 'GET', endpoint: `schedules` })
    },

    getUserSchedules(){
      return this.API({ method: 'GET', endpoint: `users/${this.userId}/schedules` })
      .then(data => { this.schedules = data; });
    },

    toggle(schedule){
      let schedules = [...this.scheduleIds];
      if(schedules.includes(schedule.xid))
        schedules = schedules.filter(s => s != schedule.xid);
      else
        schedules.push(schedule.xid);
      let data = { schedules };
      let rq = this.API({ method: 'PATCH', endpoint: `users/${this.userId}/schedules`, data });
      this.$refs.Modal.load(rq, true);
    }


  },
}
</script>
